import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PaymentProvider } from 'pms_enums';
import { ApiService } from 'api_service';
import { StyleService } from 'services/style.service';

@Injectable({providedIn: 'root'})
export class PmsPaymentService {
  paymentSubj: Subject<any> = new Subject<string>();
  receivedPayment: Observable<any> = this.paymentSubj.asObservable();

  constructor(private api: ApiService,
              private styleService: StyleService) {
  }

  loadAssets(api: any) {
    let subdomain;
    switch (api.provider) {
      case PaymentProvider.concardis:
        subdomain = api.test_env ? 'pptest' : 'pp';
        this.styleService.scriptToHead('https://' + subdomain + '.payengine.de/widgetjs/payengine.widget.min.js');
        break;
      case PaymentProvider.adyen:
        this.styleService.scriptToHead('https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/5.39.1/adyen.js');
        this.styleService.loadCSS('https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/5.39.1/adyen.css');
        break;
      case PaymentProvider.datatrans:
        subdomain = api.test_env ? 'pay.sandbox' : 'pay';
        this.styleService.scriptToHead('https://' + subdomain + '.datatrans.com/upp/payment/js/datatrans-2.0.0.js')
    }
  }

  // <editor-fold desc="Payment">
  makePayment(data: any): Observable<Object> {
    return this.api.post('pms/payment', data);
  }

  paymentDetails(data: any): Observable<Object> {
    return this.api.post('pms/payment/details', data);
  }

  checkPayment(token: any): Observable<Object> {
    return this.api.put('pms/payment/' + token);
  }

  // Terminal

  sendToTerminal(data: any): Observable<Object> {
    return this.api.post('pms/payment_terminal', data);
  }

  makeTerminalPreAuth(data: any): Observable<Object> {
    return this.api.post('pms/pre_auth_terminal', data);
  }

  abortTerminal(data: any): Observable<Object> {
    return this.api.post('pms/payment_terminal/abort', data);
  }

  // </editor-fold>

  // <editor-fold desc="PreAuth">
  makePreAuth(data: any): Observable<Object> {
    return this.api.post('pms/pre_auth', data);
  }

  preAuthDetails(data: any): Observable<Object> {
    return this.api.post('pms/pre_auth/details', data);
  }

  getAuthAmount(amount, id, without_folio = false, no_folios_received = false, viewable_folios = true) {
    return this.api.silentPut('pms/pre_auth/amount', {amount: amount, resId: id, without_folio: without_folio, no_folios_received: no_folios_received, viewable_folios: viewable_folios});
  }

  usePreAuth(data: any): Observable<Object> {
    return this.api.post('pms/pre_auth/use', data);
  }

  // </editor-fold>

  useTokenizedCard(data: any): Observable<Object> {
    return this.api.post('pms/card', data);
  }

  transactionCreated(path: string, data: any): Observable<Object> {
    return this.api.post('pms/' + path + '/success', data);
  }

  setPayment(data: any): void {
    if (data.success) {
      this.paymentSubj.next(data.folios);
    }
  }
}
